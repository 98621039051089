import * as Dom from "./util/dom"
import { initializeTooltip } from "./site/tooltip"
import { calendarSettings } from "./site/calendar"
import { isoDateFormatter } from "./util/formatter"
import { select } from "d3-selection"
import $ from "jquery"

export module PowerSearch {
  type SearchType = "universal" | "power"
  const searchForm = document.querySelector(
    "form.power-search"
  ) as HTMLFormElement

  module FilterDomUtils {
    export function removeParentLine(this: any) {
      $(this)
        .closest(".search-filter")
        .transition({
          duration: 200,
          onComplete() {
            this.remove()
          },
        })
    }
    export function cloneLine(prototypeId: string, target: HTMLElement) {
      const element = document.getElementById(prototypeId)?.children[0]
      const clonedElement = element?.cloneNode(true) as HTMLElement
      clonedElement.style.display = "none"
      target.appendChild(clonedElement)
      $(clonedElement).transition({ duration: 200 })

      initializeTooltip($(clonedElement).find(".tooltip-host"))

      return clonedElement
    }
  }

  module FinancialFiltersDom {
    const financialFiltersRoot = document.getElementById(
      "financial-filters"
    ) as HTMLElement

    const classes = {
      filteredItem: "filtered",
      labeledInput: ["right", "labeled"],
    }

    export function addFinancialFilter() {
      return FilterDomUtils.cloneLine(
        "financial-filter-prototype",
        financialFiltersRoot
      )
    }

    export function initializeComponents(searchFilter?: HTMLElement) {
      const searchFilters = searchFilter
        ? $(searchFilter)
        : $("#financial-filters .search-filter")

      searchFilters.each(function () {
        const searchFilter = $(this)
        const dropdown = searchFilter.find(".ui.indicator.dropdown")
        const boundInputs = searchFilter.find(".ui.bound.input")
        const unitLabels = boundInputs.find(".ui.label:not(.dropdown)")
        const unitDropdowns = boundInputs.find(".ui.dropdown").dropdown()

        function resetLabels() {
          boundInputs.removeClass(classes.labeledInput)
          unitLabels.hide()
          unitDropdowns.hide()
        }

        function recreateUnitDropdowns(units: [string, string][]) {
          unitDropdowns.each(function () {
            const unitDropdown = $(this)
            const value = unitDropdown.dropdown("get value")
            unitDropdown.dropdown({
              values: units.map(([value, name]) => ({ value, name })),
            })
            unitDropdown.dropdown("set selected", value)
            if (!unitDropdown.dropdown("get value") && units.length > 0) {
              unitDropdown.dropdown("set selected", units[0][0])
            }
          })
        }

        function limitSelectableUnits(units: [string, string][]) {
          resetLabels()
          recreateUnitDropdowns(units)

          if (units.length === 1) {
            boundInputs.addClass(classes.labeledInput)
            unitLabels.text(units[0][1])
            unitLabels.show()
          } else if (units.length > 1) {
            boundInputs.addClass(classes.labeledInput)
            unitDropdowns.show()
          }
        }

        function getApplicableUnitsForIndicator(
          value: string
        ): [unitId: string, unitText: string][] {
          const item = dropdown.dropdown("get item", value) || undefined
          return item?.data("units") ?? []
        }

        function onChange(value: string) {
          limitSelectableUnits(getApplicableUnitsForIndicator(value))
        }

        dropdown.dropdown({ onChange })
        onChange(dropdown.dropdown("get value"))
      })
    }

    select("#add-financial-filter").on("click", () =>
      initializeComponents(addFinancialFilter())
    )
    Dom.onEvent(
      "click",
      financialFiltersRoot,
      ".remove-financial-filter",
      FilterDomUtils.removeParentLine
    )
  }

  module EventFiltersDom {
    const eventFiltersRoot = document.getElementById(
      "event-filters"
    ) as HTMLElement
    export function addEventFilter() {
      const newLine = FilterDomUtils.cloneLine(
        "event-filter-prototype",
        eventFiltersRoot
      )

      const eventTypeSelect = $(".ui.dropdown", newLine)
      const rangeStartCalendar = $(".ui.calendar.range-start", newLine)
      const rangeEndCalendar = $(".ui.calendar.range-end", newLine)

      eventTypeSelect.dropdown()
      rangeStartCalendar.calendar({
        ...calendarSettings,
        startMode: "year",
        endCalendar: rangeEndCalendar,
      })
      rangeEndCalendar.calendar({
        ...calendarSettings,
        startMode: "year",
        today: true,
        startCalendar: rangeStartCalendar,
      })
    }
    select("#add-event-filter").on("click", addEventFilter)
    Dom.onEvent(
      "click",
      eventFiltersRoot,
      ".remove-event-filter",
      FilterDomUtils.removeParentLine
    )
  }

  export function readPowerSearchInput(): { [key: string]: any } {
    const result: Record<string, any> = {}
    for (const field of [
      "keywords",
      "countries",
      "address",
      "coord",
      "bbox",
      "maxDistance",
      "distanceUnit",
      "legalForm",
      "status",
      "segmentCodeStandard",
      "segmentCodes",
    ]) {
      const input = searchForm[field]
      //console.info("input", input)
      if (input) {
        let value = input.value
        if (value && ["legalForm", "status", "countries"].indexOf(field) >= 0) {
          value = value.replace(/,/g, "|").replace("-", "")
        }
        result[field] = value
        //console.info("value", value)
      }
    }
    const financialIdInputs = searchForm.querySelectorAll("[name=indicatorId]")
    const nFinancialFilters = financialIdInputs && financialIdInputs.length
    if (nFinancialFilters) {
      const financialFilters: Record<string, any>[] = []
      for (let i = 0; i < nFinancialFilters; ++i) {
        financialFilters.push({})
      }
      const arrays: Record<string, any> = {}
      for (const field of [
        "indicatorId",
        "lowerBound",
        "upperBound",
        "lowerBoundUnit",
        "upperBoundUnit",
      ]) {
        arrays[field] = []
        const inputs = searchForm.querySelectorAll("[name='" + field + "']")
        for (let i = 0; i < nFinancialFilters; ++i) {
          const input = inputs[i] as HTMLInputElement
          const financialFilter = financialFilters[i]
          financialFilter[field] = input && input.value
        }
      }
      const validFinancialFilters = []
      for (let i = 0; i < nFinancialFilters; ++i) {
        const financialFilter = financialFilters[i]
        console.info(financialFilter)
        const financialId = financialFilter["indicatorId"]
        if (financialId && financialId != "-") {
          validFinancialFilters.push(financialFilter)
        }
      }
      for (const field of [
        "indicatorId",
        "lowerBound",
        "upperBound",
        "lowerBoundUnit",
        "upperBoundUnit",
      ]) {
        const values = []
        for (let i = 0; i < validFinancialFilters.length; ++i) {
          const financialFilter = validFinancialFilters[i]
          values.push(financialFilter[field])
        }
        result[field] = values.map(encodeURIComponent).join("|")
      }
    }
    const eventTypeInputs = searchForm.querySelectorAll("[name=eventType]")
    const nEventFilters = eventTypeInputs && eventTypeInputs.length
    if (nEventFilters) {
      const eventFilters: Record<string, any>[] = []
      for (let i = 0; i < nEventFilters; ++i) {
        eventFilters.push({})
      }
      const arrays: Record<string, any> = {}
      for (const field of ["eventType", "minDate", "maxDate"]) {
        arrays[field] = []
        const inputs = searchForm.querySelectorAll("[name='" + field + "']")
        for (let i = 0; i < nEventFilters; ++i) {
          const input = inputs[i] as HTMLInputElement
          const eventFilter = eventFilters[i]
          if (field === "minDate" || field === "maxDate") {
            const date = $(input).closest(".ui.calendar").calendar("get date")
            eventFilter[field] = date ? isoDateFormatter(date) : ""
          } else {
            eventFilter[field] = input && input.value
          }
        }
      }
      const validEventFilters = []
      for (let i = 0; i < nEventFilters; ++i) {
        const eventFilter = eventFilters[i]
        console.info(eventFilter)
        const eventType = eventFilter["eventType"]
        if (eventType && eventType != "-") {
          validEventFilters.push(eventFilter)
        }
      }
      for (const field of ["eventType", "minDate", "maxDate"]) {
        const values = []
        for (let i = 0; i < validEventFilters.length; ++i) {
          const eventFilter = validEventFilters[i]
          values.push(eventFilter[field])
        }
        result[field] = values.map(encodeURIComponent).join("|")
      }
    }
    result["search"] = "power"
    return result
  }

  export function writePowerSearchInput(params: Record<string, any>) {
    for (const field of [
      "keywords",
      "address",
      "coord",
      "bbox",
      "maxDistance",
    ]) {
      let inputValue = params[field]
      const input = searchForm[field]
      if (inputValue && input) {
        input.value = inputValue
      }
    }
    for (const field of [
      "legalForm",
      "status",
      "countries",
      "distanceUnit",
      "segmentCodeStandard",
      "segmentCodes",
    ]) {
      const input = searchForm[field]
      const value = params[field]
      if (input && value) {
        const values = value.split("|")
        if (
          $ &&
          $(input.parentElement).dropdown &&
          $(input.parentElement).dropdown("has items")
        ) {
          for (const value of values) {
            // Set each value individually to properly handle special characters e.g. ampersand
            $(input.parentElement).dropdown("set selected", value)
          }
        } else {
          input.value = value
        }
      }
    }
    const financialIdsParam = params["indicatorId"]
    if (financialIdsParam) {
      const financialIds = financialIdsParam.split("|")
      const nFilters = financialIds.length
      for (let i = 0; i < nFilters; ++i) {
        FinancialFiltersDom.addFinancialFilter()
      }
      for (const field of [
        "indicatorId",
        "lowerBound",
        "upperBound",
        "lowerBoundUnit",
        "upperBoundUnit",
      ]) {
        const valuesParam = params[field]
        const inputs = searchForm.querySelectorAll("[name='" + field + "']")
        if (valuesParam && inputs) {
          const values = valuesParam.split("|")
          for (let i = 0; i < nFilters; ++i) {
            const input = inputs[i] as HTMLInputElement
            input.value = values[i]
          }
        }
      }
      FinancialFiltersDom.initializeComponents()
    }
    const eventTypesParam = params["eventType"]
    if (eventTypesParam) {
      const eventTypes = eventTypesParam.split("|")
      const nFilters = eventTypes.length
      for (let i = 0; i < nFilters; ++i) {
        EventFiltersDom.addEventFilter()
      }
      for (const field of ["eventType", "minDate", "maxDate"]) {
        const valuesParam = params[field]
        const inputs = searchForm.querySelectorAll("[name='" + field + "']")
        if (valuesParam && inputs) {
          const values = valuesParam.split("|")
          for (let i = 0; i < nFilters; ++i) {
            const input = inputs[i] as HTMLInputElement
            input.value = values[i]
            input.defaultValue = values[i]
            if (field === "minDate" || field === "maxDate") {
              $(input).closest(".ui.calendar").calendar("set date", values[i])
            }
          }
        }
      }
    }
  }

  function showSearchInputs(search?: SearchType): void {
    searchForm.setAttribute("data-search-type", search || "universal")
  }

  function isNotEmpty(object: any) {
    return typeof object !== "undefined" && object !== null && object != ""
  }
}
