import { select } from "d3-selection"
import { extent } from "d3-array"
import { setHeight, size } from "../../util/dom"
import {
  bigNodeRadius,
  drawNodes,
  syncTextBackground,
  createArrowPath,
  Node,
  Link,
  enrichNodes,
} from "../nodes"
import { Renderer, Rendition } from "../renderer"
import { Widget } from "../widget"
import { dendrogram } from "./dendrogram"
import { trilingual } from "../i18n"

interface GraphLink extends Link {
  old: boolean
  warn: boolean
  head?: string
  tail?: string
  text: string
  type: string
  length?: unknown
  helper?: unknown
}

interface Data {
  nodes: Node[]
  links: GraphLink[]
  children: unknown
  diameter: number
  type?: string
  html?: string
}

export const graph: Renderer = function (
  widget: Widget,
  data: Data
): Rendition {
  if (data.children) {
    return dendrogram(widget, data)
  }

  const orgchart = "orgchart" == data.type
  const container = widget.container

  if (data.html) {
    container.innerHTML = data.html
    syncTextBackground(
      select(container).selectAll(".link"),
      ".desc",
      ".desc-bg",
      3,
      3,
      1000
    )
    enrichNodes(widget, select(container).select("svg"), false)
    return { draw: function () {} }
  }

  if (!data.nodes || data.nodes.length < 2) {
    throw new Error("graph empty or single node")
  }

  let rootNode: Node
  const nodeById: Record<number, Node> = {}
  const count = data.nodes.length
  let showWarningLegend: boolean = false
  for (let node of data.nodes) {
    if (node.root) {
      rootNode = node
    }
    if (node.warning) {
      showWarningLegend = true
    }
    nodeById[node.id] = node
  }

  for (let link of data.links) {
    // source and target are transmitted as ids (number) pointing to nodes
    // rewrite links so that source and target point to actual Node
    link.source = nodeById[link.source as unknown as number]
    link.target = nodeById[link.target as unknown as number]
    if (!link.source || !link.target) {
      console.error("broken link: ", link)
    }
    //  link.strength = Math.exp(- link.length / 3);
  }

  const svg = select(container)
    .append("svg")
    .attr("width", "100%")
    .attr("height", "100%")
    .attr("aria-label", trilingual("Network", "Netzwerk", "Réseau"))

  const drawnLinks = svg
    .append("g")
    .attr("class", "nodes")
    .selectAll(".link")
    .data(data.links)

  const legend = svg.append("g").attr("class", "legend")

  if (!orgchart) {
    legend.append("line").attr("data-old", false)
    legend.append("line").attr("data-old", true)
  }

  if (showWarningLegend) {
    legend.append("line").attr("data-warn", true)
  }

  legend
    .append("text")
    .attr("data-old", false)
    .text(widget.trilingual("currently", "aktuell", "actuel"))

  legend
    .append("text")
    .attr("data-old", true)
    .text(widget.trilingual("previously", "vormals", "auparavant"))

  if (showWarningLegend) {
    legend
      .append("text")
      .attr("data-warn", true)
      .text(
        widget.trilingual(
          "politically exposed",
          "politisch exponiert",
          "politiquement exposé"
        )
      )
  }

  function dashArray(link: GraphLink): string | undefined {
    if (link.type == "SameAddress") {
      return "3, 3"
    }
    if (link.type == "CommonFiling") {
      return "6, 6"
    }
  }

  const newDrawnLinks = drawnLinks
    .enter()
    .append("g")
    .attr("class", "link")
    .attr("data-source-id", (link) => link.source.id)
    .attr("data-target-id", (link) => link.target.id)
    .attr("data-head", (link) => link.head as string | null)
    .attr("data-tail", (link) => link.tail as string | null)
    .attr("data-old", (link) => link.old)
    .attr("data-warn", (link) => link.warn)
    .attr("data-length", (link) => link.length as any)
    .attr("data-helper", (link) => link.helper as any)

  if (orgchart) {
    for (var i = 0; i < 3; ++i) {
      newDrawnLinks
        .append("line")
        .attr("class", "line")
        .attr("class", "segment" + i)
        .attr("stroke-width", 1)
        .attr("stroke", "#000000")
      //      .attr("stroke-dasharray", (link) => dashArray(link) as string)
    }
  } else {
    newDrawnLinks
      .append("line")
      .attr("class", "line")
      .attr("stroke-width", 1)
      .attr("stroke", "#000000")
      .attr("stroke-dasharray", (link) => dashArray(link) as string)
  }

  newDrawnLinks.append("rect").attr("class", "desc-bg")

  newDrawnLinks
    .append("text")
    .attr("class", "desc")
    .attr("text-anchor", "middle")
    .text((link) => link.text)

  newDrawnLinks
    .append("path")
    .attr("class", "head")
    .attr("d", createArrowPath(12))

  newDrawnLinks
    .append("path")
    .attr("class", "tail")
    .attr("d", createArrowPath(12))

  const drawnNodes = drawNodes(widget, svg, data.nodes, rootNode!.id, false)

  let ticks = 0
  let warmingUp = true

  const render = function () {
    if (warmingUp || ticks++ % 3 != 0) {
      return
    }

    function ratio(domain: [number, number], range: [number, number]) {
      return (range[1] - range[0]) / (domain[1] - domain[0])
    }
    function midpoint(domain: [number, number]) {
      return (domain[1] + domain[0]) / 2
    }

    const rect = container.getBoundingClientRect()
    const width = rect.width
    const height = rect.height
    const hSpace = 100,
      vSpace = 40
    const heightFor2 = 120
    const hDomain = extent(data.nodes, (node) => node.x) as [number, number]
    const vDomain = extent(data.nodes, (node) => node.y) as [number, number]

    const hRange: [number, number] = [hSpace, width - hSpace]
    const vRange: [number, number] = orgchart
      ? vDomain
      : [vSpace, (count == 2 ? heightFor2 : height) - 1.5 * vSpace]

    const hDomainMidpoint = midpoint(hDomain)
    const hRangeMidpoint = midpoint(hRange)
    const vDomainMidpoint = midpoint(vDomain)
    const vRangeMidpoint = midpoint(vRange)
    let hRatio = ratio(hDomain, hRange)
    let vRatio = ratio(vDomain, vRange)
    let maxAspectRatio = count == 2 ? 2 : data.diameter * 1.1
    hRatio = Math.min(hRatio, vRatio * maxAspectRatio)

    if (count == 2) {
      vRatio = 0
      hRatio = hRatio * 2
      setHeight(container, heightFor2)
    }

    const scaleH = (x: number) =>
      (x - hDomainMidpoint) * hRatio + hRangeMidpoint
    const scaleV = (y: number) =>
      (y - vDomainMidpoint) * vRatio + vRangeMidpoint

    const x1 = (link: GraphLink) => scaleH(link.source.x)
    const x2 = (link: GraphLink) => scaleH(link.target.x)
    const y1 = (link: GraphLink) => scaleV(link.source.y)
    const y2 = (link: GraphLink) => scaleV(link.target.y)

    const dx = (link: GraphLink) => x2(link) - x1(link)
    const dy = (link: GraphLink) => y2(link) - y1(link)
    const rx = (link: GraphLink) => (x2(link) - x1(link)) / 2
    const ry = (link: GraphLink) => (y2(link) - y1(link)) / 2

    const rawAngle = (link: GraphLink) => {
      let result = (180 / Math.PI) * Math.atan2(dy(link), dx(link))
      return result
    }

    const angle = (link: GraphLink) => {
      let result = (180 / Math.PI) * Math.atan2(dy(link), dx(link))
      if (result > 90 && result < 270) {
        result = result - 180
      } else if (result < -90 && result > -270) {
        result = result + 180
      }
      return result
    }

    newDrawnLinks.attr(
      "transform",
      (link) => "translate(" + x1(link) + " " + y1(link) + ")"
    )

    newDrawnLinks
      .select("line")
      .attr("x1", 0)
      .attr("y1", 0)
      .attr("x2", dx)
      .attr("y2", dy)

    // the three segments are used for org charts
    newDrawnLinks
      .select(".segment0")
      .attr("x1", 0)
      .attr("y1", 0)
      .attr("x2", 0)
      .attr("y2", ry) // /2

    newDrawnLinks
      .select(".segment1")
      .attr("x1", 0)
      .attr("y1", ry)
      .attr("x2", dx)
      .attr("y2", ry)

    newDrawnLinks
      .select(".segment2")
      .attr("x1", dx)
      .attr("y1", ry)
      .attr("x2", dx)
      .attr("y2", dy)

    const linkDescTransform = (link: GraphLink) =>
      "rotate(" +
      angle(link) +
      " " +
      rx(link) +
      " " +
      ry(link) +
      " " +
      ") translate(0 -7)"

    newDrawnLinks
      .select(".desc")
      .attr("x", rx)
      .attr("y", ry)
      .attr("transform", linkDescTransform)

    newDrawnLinks
      .select(".desc-bg")
      .attr("x", rx)
      .attr("y", ry)
      .attr("transform", linkDescTransform)

    // ARROW HEADs
    if (!orgchart) {
      const xHead = (link: GraphLink) => dx(link) + 0
      const yHead = (link: GraphLink) => dy(link) + 0

      newDrawnLinks
        .select(".tail")
        .attr(
          "transform",
          (link) =>
            "rotate(" +
            rawAngle(link) +
            ") " +
            "translate(" +
            bigNodeRadius +
            " " +
            0 +
            ")"
        )

      newDrawnLinks
        .select(".head")
        .attr(
          "transform",
          (link) =>
            "translate(" +
            xHead(link) +
            " " +
            yHead(link) +
            ") " +
            "rotate(" +
            (180 + rawAngle(link)) +
            ") " +
            "translate(" +
            bigNodeRadius +
            " " +
            0 +
            ")"
        )
    }

    // LEGEND
    const legendLeft = hSpace / 2
    const legendRight = legendLeft + 40
    const legendYOld = vRange[1] + vSpace / 2
    const legendY = legendYOld - 18
    const legendYWarn = legendYOld + 18

    svg.selectAll(".legend line").attr("x1", legendLeft).attr("x2", legendRight)

    svg
      .selectAll(".legend line[data-old='false'")
      .attr("y1", legendY)
      .attr("y2", legendY)
    svg
      .selectAll(".legend line[data-old='true'")
      .attr("y1", legendYOld)
      .attr("y2", legendYOld)
    svg
      .selectAll(".legend line[data-warn='true'")
      .attr("y1", legendYWarn)
      .attr("y2", legendYWarn)

    svg.selectAll(".legend text").attr("x", legendRight + 5)

    const textDy = 3
    svg.selectAll(".legend text[data-old='false'").attr("y", legendY + textDy)
    svg.selectAll(".legend text[data-old='true'").attr("y", legendYOld + textDy)
    svg
      .selectAll(".legend text[data-warn='true'")
      .attr("y", legendYWarn + textDy)

    syncTextBackground(newDrawnLinks, ".desc", ".desc-bg", 3, 3, 1000)

    drawnNodes
      .attr("data-dx", (node) => scaleH(node.x))
      .attr(
        "transform",
        (node) => "translate(" + scaleH(node.x) + "," + scaleV(node.y) + ")"
      )
  }

  function draw() {
    const [width, height] = size(container.getBoundingClientRect())
    svg.attr("viewBox", "0 0 " + width + " " + height)

    warmingUp = false
    render()
  }
  return { draw }
}
