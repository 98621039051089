export type Data = any
export type Linkifier = (node: Element) => string
export type Handler = (param1?: any, param2?: any, param3?: any) => any
export type Callback = (value: any) => void

export function getDataDomain(): string {
  const scripts = document.scripts
  for (let i = 0; i < scripts.length; ++i) {
    const script = scripts[i]
    const src = script["src"]
    if (src && src.indexOf("/viz.") >= 0) {
      const pos = src.indexOf("/", 9)
      if (pos >= 0) {
        return src.substring(0, pos)
      }
    }
  }
  return ""
}
