import { importMap } from "../../dynamic-modules"
import { alreadyDrawn, Renderer } from "../../viz/renderer"
import { Widget } from "../../viz/widget"
import { json } from "d3-fetch"
import { toQueryString } from "../../util/string"
import { SearchResults } from "../northdata-api"
import { MapMarkerOptions, Map } from "../map"
import { createContentFromCompany, createContent } from "../map-marker"
import { blue, blueHover } from "../colors"

const nearbyMaxDistance = 0.5

async function fetchData(lat: number, lng: number) {
  const queryParameters = {
    coord: `${lat}|${lng}`,
    maxDistance: nearbyMaxDistance,
  }
  return await json<SearchResults>(
    `/nearby.json?${toQueryString(queryParameters)}`
  )
}

function updateMap(
  map: Map,
  companyResult: SearchResults | undefined,
  companyId: string
) {
  if (companyResult?.results) {
    const mapMarkers: MapMarkerOptions[] = companyResult.results
      .filter(({ company }) => company?.address.lat && company?.address.lng)
      .filter(({ company }) => company?.id !== companyId)
      .map(({ company }) => ({
        id: company!.id,
        lat: company!.address.lat!,
        lng: company!.address.lng!,
        title: company!.name.name,
        content: createContentFromCompany(company!),
      }))
    map.addMarkers(mapMarkers)
    map.fitBounds()
  }
}

async function initializeMap(mapElement: HTMLElement) {
  const { drawMap } = await importMap()
  return drawMap(mapElement)
}

export const companyMap: Renderer = function (widget: Widget, _data: any) {
  const mapElement = widget.container
  const name = mapElement.dataset.name!
  const id = mapElement.dataset.id!
  const lat = Number(mapElement.dataset.lat!)
  const lng = Number(mapElement.dataset.lng!)

  async function initialize() {
    const [map, searchResults] = await Promise.all([
      initializeMap(widget.container),
      fetchData(lat, lng),
    ])

    map.addMarkers([
      {
        id: id,
        lat: lat,
        lng: lng,
        title: name,
        color: blue,
        colorHover: blueHover,
        content: createContent({ title: name }),
      },
    ])

    updateMap(map, searchResults, id)
  }

  initialize()

  return alreadyDrawn
}
