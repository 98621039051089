export function trim(s: string) {
  return s ? s.trim() : s
}

export function unescape(s: string): string | null {
  if (s) {
    const el = document.createElement("div")
    el.innerHTML = s
    return el.innerText || el.textContent
  }
  return s
}

export function encode(s: string): string {
  if (s) {
    s = encodeURIComponent(s).replace(/%20/g, "+")
  }
  return s
}

export function decode(s: string): string {
  if (s) {
    s = decodeURIComponent(s.replace(/\+/g, "%20"))
  }
  return s
}

export function camelCased(s: string): string {
  return s.replace(/-([a-z])/g, (g) => g[1].toUpperCase())
}

export function fromQueryString(query: string): Record<string, any> {
  const result: Record<string, any> = {}
  const a = (query[0] === "?" ? query.substr(1) : query).split("&")
  for (let i = 0; i < a.length; i++) {
    const b = a[i].split("=")
    result[decode(b[0])] = decode(b[1] || "")
  }
  return result
}

export function toQueryString(object: Record<string, any>): string {
  const parts: string[] = []
  for (let i in object) {
    if (object.hasOwnProperty(i)) {
      let value = object[i]
      if (typeof value === "object") {
        value = JSON.stringify(value)
      }
      if (typeof value === "string" || typeof value === "number") {
        parts.push(encode(i) + "=" + encode(value as any))
      }
    }
  }
  return parts.join("&")
}
