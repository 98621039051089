import { registerRenderers } from "../viz/renderer"

import { netCompanyCount } from "./widgets/net-company-count"
import { companyMap } from "./widgets/company-map"
import { insolvenciesMap } from "./widgets/insolvencies-map"
import { innorankingMap } from "./widgets/innoranking-map"
import { insolvenciesStaticMap } from "./widgets/insolvencies-static-map"

export function registerSiteWidgets() {
  registerRenderers({
    netCompanyCount,
    companyMap,
    insolvenciesMap,
    innorankingMap,
    insolvenciesStaticMap,
  })
}
