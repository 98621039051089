import { select } from "d3-selection"

import { Data } from "../base"
import { alreadyDrawn, Renderer, Rendition } from "../renderer"
import { Widget } from "../widget"

export const personTable: Renderer = function (
  widget: Widget,
  data: Data
): Rendition {
  const toDate = (value: string) => (value ? new Date(value) : null)
  const formatDate = (value: string) => toDate(value)?.toLocaleDateString()

  const table = select(widget.container)
    .append("table")
    .attr(
      "class",
      "ui bizq very compact celled small unstackable striped table"
    )
  const tbody = table.append("tbody")

  console.info("data", data)
  for (const section of ["current", "former"]) {
    if (section == "former")
      tbody
        .append("tr")
        .append("td")
        .attr("class", "first")
        .attr("colspan", 2)
        .text("Vormals")
    const groups = data["current"]
    for (const group of groups) {
      console.info("group", group)
      const name = group.name
      const entries = group.entries

      tbody
        .append("tr")
        .append("td")
        .attr("class", "first")
        .attr("colspan", 2)
        .text(name)

      for (const entry of entries) {
        console.info("entry", entry)
        const tr = tbody.append("tr")
        const person = entry.person
        const personCell = tr
          .append("td")
          .append("span")
          .text(person.name)
          .attr("class", "person")
        for (const key in person.query) {
          personCell.attr(key, person.query[key])
        }
        const chunkCell = tr.append("td")
        for (const chunk of entry.chunks) {
          let text: string | undefined = undefined
          if (!chunk.start && chunk.end) {
            text = "bis " + formatDate(chunk.end)
          } else if (chunk.start && !chunk.end) {
            text = "seit " + formatDate(chunk.start)
          } else if (chunk.start && chunk.end) {
            text = formatDate(chunk.start) + " – " + formatDate(chunk.end)
          }
          chunkCell.append("span").text(text as string)
        }
      }
    }
  }

  widget.markup(table)

  return alreadyDrawn
}
