import { select, event } from "d3-selection"
import { trim, toQueryString } from "../../util/string"
import { Renderer, Rendition, alreadyDrawn } from "../renderer"
import { Widget } from "../widget"
import { Data } from "../base"
import { mixin } from "../../util/params"
import { dataSource } from "../datasource"

// Improve types when working on this!

export const searchResults: Renderer = function (
  widget: Widget,
  data: Data
): Rendition {
  const root = select(widget.container)
  const searchResults = root.append("ul").attr("class", "search-results")
  const cursor = data.cursor
  function transformResult(item: any): any {
    const query: any = {}
    const subject = item.subject
    const name = subject.name[0].name
    const address = subject.address && subject.address[0]
    const city = address && address.city
    const result: any = {
      query,
      address,
      name,
      details: item.formattedDetails,
    }
    const tags = subject["tag"]
    if (tags && tags["terminated"]) {
      result["terminated"] = true
    }
    switch (item.targetType) {
      case "Company":
        result["type"] = "c"
        query["address"] = city
        query["name"] = name
        const register = subject.register && subject.register[0]
        if (register && register.id) {
          if (register.city) {
            query["registerCity"] = register.city
          }
          query["registerId"] = register.id
        }
        break
      case "Person":
        result["type"] = "p"
        const nameParts = name.split(",")
        query["lastName"] = nameParts[0]
        query["firstName"] = trim(nameParts[1])
        query["birthDate"] = subject.birthDate
        query["address"] = city
        if (!query["address"] || !query["birthDate"]) {
          query["id"] = item.targetId
        }
        break
    }
    return result
  }

  const more = root.append("div").attr("class", "more")
  const resultMessageNode = more.append("p")
  const moreLink = more
    .append("a")
    .attr("href", "#")
    .on("click", function () {
      const clickEvent = event as Event
      const newOptions = mixin(widget.options, {
        offset: moreLink.attr("data-offset"),
        position: moreLink.attr("data-position"),
      })
      dataSource.requestData(
        newOptions,
        function (data) {
          drawResultItems(data)
        },
        widget.fail.bind(widget)
      )

      clickEvent.preventDefault()
    })

  const noResults = cursor.items.length == 0
  const onNoResults = widget.handlerValue("noResults")
  const onOneResult = widget.handlerValue("oneResult")
  if (noResults) {
    if (onNoResults) {
      onNoResults()
    } else {
      root.append("p").text("Keine Resultate zu dieser Suche.")
    }
  } else if (cursor.items.length == 1 && onOneResult) {
    onOneResult(transformResult(cursor.items[0]))
  } else {
    drawResultItems(data)
  }

  function drawResultItems(data: any) {
    const cursor = data.cursor
    for (const _item of cursor.items) {
      const item = transformResult(_item)
      const query = item["query"]
      const city = query["address"]
      let linkText = query["name"]
      if (!linkText) {
        linkText = query["lastName"] + ", " + query["firstName"]
      }
      if (city) {
        linkText += ", " + city
      }
      const searchResult = searchResults
        .append("li")
        .attr("class", "search-result")
      searchResult
        .append("span")
        .attr("class", "icon")
        .text(_item.targetType == "Company" ? "\uf1ad" : "\uf007")
      const searchResultBody = searchResult.append("div").attr("class", "body")
      const link = searchResultBody
        .append("a")
        .attr("href", document.location.pathname + "?" + toQueryString(query))
        .text(linkText)
        .on("click", function () {
          const clickEvent = event as Event
          if (widget.invokeClickHandler(data)) {
            clickEvent.preventDefault()
          }
        })
      if (item["terminated"]) {
        const terminationSymbol = "✝\uFE0E"
        link.append("sup").attr("title", "erloschen").text(terminationSymbol)
      }
      searchResultBody
        .append("div")
        .attr("class", "detail")
        .text(item["detail"])
      for (let formattedDetail of _item["formattedDetails"]) {
        searchResultBody
          .append("div")
          .attr("class", "detail")
          .text(formattedDetail)
      }
    }
    more.attr("data-total", cursor.total)

    let resultMessage = Number(
      cursor.offset + cursor.items.length
    ).toLocaleString()
    if (cursor.total) {
      resultMessage +=
        " Resultate von " +
        Number(cursor.total).toLocaleString() +
        " insgesamt."
    } else {
      resultMessage += " Resultate."
    }
    resultMessageNode.text(resultMessage)

    moreLink
      .text(cursor.lastPage ? "" : "Weitere Resultate anzeigen")
      .attr("data-offset", cursor.offset + cursor.limit)
      .attr("data-position", data.properties.nextPosition)
  }
  return alreadyDrawn
}
