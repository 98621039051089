// See site-vars.less

export const blue = "#007388"
export const blueHover = "#005d6f"
export const grey = "#9ab5c1"
export const greyHover = "#aac1cb"
export const teal = "#66afac"
export const tealHover = "#52a9a6"
export const olive = "#9baf66"
export const oliveHover = "#91a952"
