import { getLanguage } from "../util/language"

function formatNumber(value: number) {
  return Number(value).toLocaleString(getLanguage())
}

export function animateNumericLabel(
  element: Element,
  interval: number,
  duration: number,
  initialPercentage = 0
) {
  const label = $(element)
  const finalValue = parseInt(label.text().trim(), 10)

  if (isNaN(finalValue)) {
    return
  }

  const stepFunction = (elapsed: number) => {
    const progress =
      Math.sqrt(elapsed / duration) * (1 - initialPercentage) +
      initialPercentage
    const value = Math.ceil(finalValue * progress)
    label.text(formatNumber(value))

    if (elapsed >= duration) {
      // Ensure that the intended value is displayed
      label.text(formatNumber(finalValue))
      return
    }
    setTimeout(stepFunction, interval, elapsed + interval)
  }

  stepFunction(0)
}
