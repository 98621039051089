import { Renderer, Rendition, alreadyDrawn } from "../renderer"
import { Widget } from "../widget"
import { Data } from "../base"

export const overview: Renderer = function (
  widget: Widget,
  data: Data
): Rendition {
  if (data) {
    const handler = widget.handlerValue("load")
    if (handler) {
      handler(data)
    }
  }

  return alreadyDrawn
}
