import { Data } from "../base"
import { alreadyDrawn, Renderer, Rendition } from "../renderer"
import { Widget } from "../widget"

export const contact: Renderer = function (
  widget: Widget,
  data: Data
): Rendition {
  if (data) {
    const handler = widget.handlerValue("load")
    if (handler) {
      handler(data)
    }
  }

  return alreadyDrawn
}
