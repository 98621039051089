import { getI18n, trilingual } from "../viz/i18n"

const i18n = getI18n()

export const calendarSettings: FomanticUI.CalendarSettings = {
  type: "date",
  text: {
    months: i18n.timeFormatLocaleDefinition.months,
    monthsShort: i18n.timeFormatLocaleDefinition.shortMonths,
    today: trilingual("Today", "Heute", "Aujourd’hui"),
  },
  formatter: {
    date: (date: Date) => i18n.dateFormatter(date),
  },
  monthFirst: false,
}
