import { select, selectAll, event, Selection, BaseType } from "d3-selection"

export function size(rect: ClientRect): [number, number] {
  return [rect.width, rect.height]
}

export function setHeight(element: HTMLElement, height: number): void {
  select(element).style("height", height + "px")
}

export function closest(
  elements: Selection<HTMLElement, unknown, null, undefined>,
  selector: string
): Selection<any, unknown, null, undefined> {
  let closestMatch: any = undefined
  let matchArr: any[] = []
  elements.each(function (this: any) {
    let elm = this
    while (typeof elm.parentNode.matches === "function" && !closestMatch) {
      elm = elm.parentNode
      if (elm.matches(selector)) {
        closestMatch = elm
        matchArr.push(closestMatch)
      }
    }
    closestMatch = undefined
  })
  return selectAll(matchArr)
}

export function isDescendant(node: Node | null, parent: Node): boolean {
  for (;;) {
    if (!node || !parent) {
      return false
    }
    if (node.isSameNode(parent)) {
      return true
    }
    node = node.parentNode
  }
}

export function onEvent<GElement extends Element, Datum>(
  eventName: string,
  parent: Element,
  children: Selection<GElement, Datum, BaseType, unknown> | string,
  handler: (value: Datum) => void
) {
  select(parent).on(eventName, function () {
    try {
      const theEvent = event as Event
      //console.info(eventName, event.target)
      let actualChildren: Selection<GElement, Datum, BaseType, unknown>
      if (typeof children === "string") {
        actualChildren = select(parent).selectAll<GElement, Datum>(children)
      } else {
        actualChildren = children
      }
      actualChildren.each(function () {
        const node = this
        if (isDescendant(theEvent.target as Node, node)) {
          handler.bind(node)(select<Element, Datum>(node).data()[0])
        }
      })
    } catch (error) {
      console.error(error)
      throw error
    }
  })
}

export function isIE() {
  let ua = window.navigator.userAgent
  return (
    ua.indexOf("MSIE ") >= 0 ||
    ua.indexOf("Edge ") >= 0 ||
    ua.indexOf("Trident") >= 0
  )
}

export function isInViewport(element: Element) {
  const rect = element.getBoundingClientRect()
  return (
    rect.bottom > 0 &&
    rect.right > 0 &&
    rect.top < (window.innerHeight || document.documentElement.clientHeight) &&
    rect.left < (window.innerWidth || document.documentElement.clientWidth)
  )
}

/**
 * Registers an event handler that executes once
 * when `element` enters the viewport
 */
export function addViewportHandler(
  element: Element,
  namespace: string,
  callback: () => void
) {
  let handleViewportChange = () => {
    if (isInViewport(element)) {
      callback()
      $(window).off(`.${namespace}`)
    }
  }

  // Register event handler and do initial check
  $(window).on(
    `scroll.${namespace} resize.${namespace}`,
    debounce(handleViewportChange, 50)
  )
  handleViewportChange()
}

export function debounce(callback: Function, wait: number) {
  let timer: number
  return (...args: any[]) => {
    window.clearTimeout(timer)
    timer = window.setTimeout(() => {
      callback(...args)
    }, wait)
  }
}
