import { Renderer, Rendition } from "../../viz/renderer"
import { Widget } from "../../viz/widget"
import { isoDateParser } from "../../util/formatter"
import { trilingual, getI18n } from "../../viz/i18n"
import { getDataItemByDate } from "../../util/date"

import { select, event, Selection, mouse } from "d3-selection"
import { scaleTime, scaleLinear, ScaleTime, ScaleLinear } from "d3-scale"
import { area, stack, stackOffsetDiverging, SeriesPoint } from "d3-shape"
import { axisBottom, axisLeft } from "d3-axis"
import { extent, max } from "d3-array"
import { brushX } from "d3-brush"
import { timeDay, timeYear } from "d3-time"

interface RawData {
  country: string
  data: [number, number, number, number, number, number][]
  startDate: string
}

interface DataItem {
  foundations: number
  foundationsMovingAverage: number
  insolvencies: number
  insolvenciesMovingAverage: number
  naturalExits: number
  naturalExitsMovingAverage: number
  date: Date
}

interface FocusChanged {
  (xFocusDomain: Date[]): void
}

interface SVGSelection extends Selection<SVGSVGElement, any, null, undefined> {}
interface SVGGSelection extends Selection<SVGGElement, any, null, undefined> {}
interface HTMLDivSelection
  extends Selection<HTMLDivElement, unknown, null, undefined> {}

const annotations = [
  {
    date: isoDateParser("2020-03-22") as Date,
    description: "COVID-19 Lockdown",
  },
]

const colors = {
  transparent: [
    "rgba(0, 115, 136, 0.75)",
    "rgba(102, 175, 139, 0.75)",
    "rgba(0, 73, 86, 0.75)",
  ],
  base: ["rgb(0, 115, 136)", "rgb(102, 175, 139)", "rgb(0, 73, 86)"],
}

const texts = {
  foundations: trilingual("Foundations", "Gründungen", "Créations"),
  insolvencies: trilingual("Insolvencies", "Insolvenzen", "Insolvabilités"),
  naturalExits: trilingual("Natural Exits", "Schließungen", "Fermetures"),
  movingAverage: trilingual(
    "Moving Average",
    "Gleitender Durchschnitt",
    "moyenne mobile"
  ),
}

function parseData({ data, startDate }: RawData): DataItem[] {
  const parsedStartDate = isoDateParser(startDate)
  const mappedData = data.map(
    (
      [
        foundations,
        insolvencies,
        naturalExits,
        foundationsMovingAverage,
        insolvenciesMovingAverage,
        naturalExitsMovingAverage,
      ],
      index
    ) => ({
      foundations,
      foundationsMovingAverage,
      insolvencies,
      insolvenciesMovingAverage,
      naturalExits,
      naturalExitsMovingAverage,
      date: timeDay.offset(parsedStartDate as Date, index),
    })
  )
  return mappedData
}

export const netCompanyCount: Renderer = function (
  widget: Widget,
  rawData: RawData
): Rendition {
  const chartHeight = 440
  const focusHeight = 100
  const margin = { top: 20, right: 30, bottom: 30, left: 40 }

  const data = parseData(rawData)
  const stackedData = stack<DataItem>()
    .keys([
      "foundationsMovingAverage",
      "naturalExitsMovingAverage",
      "insolvenciesMovingAverage",
    ])
    .value((d, key) =>
      key === "foundationsMovingAverage" ? d[key] : -d[key as keyof DataItem]
    )
    .offset(stackOffsetDiverging)(data)

  function calcYDomain(xDomain: Date[]) {
    const [minDate, maxDate] = xDomain
    const dataInXDomain = data.filter(
      (d) => d.date >= minDate && d.date <= maxDate
    )
    return [
      (max(
        dataInXDomain,
        (d) => d.insolvenciesMovingAverage + d.naturalExitsMovingAverage
      ) ?? 0) * -1,
      max(dataInXDomain, (d) => d.foundationsMovingAverage),
    ].map((value) => value ?? 0 * 1.1)
  }

  function xAxis(
    g: SVGGSelection,
    xScale: ScaleTime<number, number>,
    height: number,
    width: number,
    gridLines: boolean
  ) {
    g.html("")
      .attr("transform", `translate(0,${height - margin.bottom})`)
      .call(
        axisBottom<Date>(xScale)
          .tickSizeOuter(0)
          .ticks(width / 100)
          .tickFormat(getI18n().timeScaleFormatter)
      )
      .attr("font-size", 12)
      .attr("font-family", null)
    if (gridLines) {
      g.call((g) =>
        g
          .selectAll(".tick line")
          .clone()
          .attr("stroke-opacity", 0.1)
          .attr("y1", 0)
          .attr("y2", -height + margin.bottom + margin.top)
      )
    }
  }

  function yAxis(
    g: SVGGSelection,
    yScale: ScaleLinear<number, number>,
    width: number
  ) {
    g.html("")
      .attr("transform", `translate(${margin.left},0)`)
      .call(axisLeft(yScale).tickSizeOuter(0))
      .attr("font-size", 12)
      .attr("font-family", null)
      .call((g) =>
        g
          .selectAll(".tick line")
          .clone()
          .attr("stroke-opacity", 0.1)
          .attr("x1", 0)
          .attr("x2", width - margin.right - margin.left)
      )
  }

  function createAnnotations(g: SVGGSelection) {
    const annotationGroups = g
      .selectAll("g")
      .data(annotations)
      .enter()
      .append("g")
    annotationGroups
      .append("text")
      .attr("font-size", 12)
      .attr("fill", "currentcolor")
      .text((d) => d.description)
      .attr("dx", (_, i, nodes) => -nodes[i].getBBox().width / 2)
    annotationGroups
      .append("line")
      .attr("stroke", "black")
      .attr("stroke-opacity", 0.75)
      .attr("fill", "none")
      .attr("stroke-dasharray", "1,3")

    return (
      xScale: ScaleTime<number, number>,
      yScale: ScaleLinear<number, number>
    ) => {
      const annotationGroups = g.selectAll("g").data(annotations)
      annotationGroups
        .select<SVGTextElement>("text")
        .attr("x", (d) => xScale(d.date) as number)
        .attr("y", margin.top)

      annotationGroups
        .select("line")
        .attr("x1", (d) => xScale(d.date) as number)
        .attr("y1", yScale(0) as number)
        .attr("x2", (d) => xScale(d.date) as number)
        .attr("y2", margin.top + 4)
    }
  }

  function createChart(
    svg: SVGSelection,
    height: number,
    hoverMoved: (
      hoverX: number,
      hoverY: number,
      viewportHoverX: number,
      d: DataItem
    ) => void,
    hoverVisibilityChanged: (isVisible: boolean) => void
  ) {
    svg.attr(
      "aria-label",
      trilingual(
        "Economic Barometer",
        "Economic Barometer",
        "Baromètre économique"
      )
    )

    const xScale = scaleTime()
    const yScale = scaleLinear().range([height - margin.bottom, margin.top])

    const clipId = "chart-clip"
    const clipRect = svg
      .append("clipPath")
      .attr("id", clipId)
      .append("rect")
      .attr("x", margin.left)
      .attr("y", 0)
      .attr("height", height)

    const xAxisGroup = svg.append("g")
    const yAxisGroup = svg.append("g")

    const updateAnnotations = createAnnotations(
      svg.append("g").attr("clip-path", `url(#${clipId})`)
    )

    const stackedAreaPaths = svg
      .append("g")
      .attr("clip-path", `url(#${clipId})`)
      .selectAll("path")
      .data(stackedData)
      .join("path")
      .attr("fill", (_, index) => colors.transparent[index])

    const hoverGroup = svg.append("g").style("display", "none")
    const hoverLine = hoverGroup
      .append("line")
      .attr("stroke", "currentColor")
      .attr("stroke-opacity", 0.2)
    const foundationsHoverCircle = hoverGroup
      .append("circle")
      .attr("r", 5)
      .attr("fill", colors.base[0])
    const naturalExitsHoverCircle = hoverGroup
      .append("circle")
      .attr("r", 5)
      .attr("fill", colors.base[1])
    const insolvenciesHoverCircle = hoverGroup
      .append("circle")
      .attr("r", 5)
      .attr("fill", colors.base[2])

    const hoverOverlay = svg
      .append("rect")
      .style("pointer-events", "all")
      .attr("fill", "none")
      .attr("x", margin.left)
      .attr("y", margin.top)
      .attr("height", height - margin.bottom - margin.top)
      .on("mouseover", () => {
        hoverGroup.style("display", null)
        hoverVisibilityChanged(true)
      })
      .on("mouseout", () => {
        hoverGroup.style("display", "none")
        hoverVisibilityChanged(false)
      })
      .on("mousemove", function mousemove() {
        const [x, y] = mouse(this)
        const date = timeDay.round(xScale.invert(x))
        const d = getDataItemByDate(data, (d) => d.date, date)

        foundationsHoverCircle
          .attr("cx", xScale(d.date) as number)
          .attr("cy", yScale(d.foundationsMovingAverage) as number)
        insolvenciesHoverCircle
          .attr("cx", xScale(d.date) as number)
          .attr(
            "cy",
            yScale(
              -d.insolvenciesMovingAverage + -d.naturalExitsMovingAverage
            ) as number
          )
        naturalExitsHoverCircle
          .attr("cx", xScale(d.date) as number)
          .attr("cy", yScale(-d.naturalExitsMovingAverage) as number)
        hoverLine
          .attr("x1", xScale(d.date) as number)
          .attr("x2", xScale(d.date) as number)
          .attr("y1", yScale(d.foundationsMovingAverage) as number)
          .attr(
            "y2",
            yScale(
              -d.insolvenciesMovingAverage + -d.naturalExitsMovingAverage
            ) as number
          )

        hoverMoved(x, y, svg.node()!.getBoundingClientRect().left + x, d)
      })

    return (width: number, xDomain: Date[]) => {
      svg.attr("viewBox", `0 0 ${width} ${height}`)

      xScale.domain(xDomain).range([margin.left, width - margin.right])
      yScale.domain(calcYDomain(xScale.domain()))

      clipRect.attr("width", width - margin.left - margin.right)

      xAxisGroup.call(xAxis, xScale, height, width, true)
      yAxisGroup.call(yAxis, yScale, width)

      stackedAreaPaths.attr(
        "d",
        area<SeriesPoint<DataItem>>()
          .x((d) => xScale(d.data.date) as number)
          .y0((d) => yScale(d[0]) as number)
          .y1((d) => yScale(d[1]) as number)
      )

      updateAnnotations(xScale, yScale)

      hoverOverlay.attr("width", width - margin.left - margin.right)
    }
  }

  function createBrush(g: SVGGSelection, height: number) {
    let focussedXDomain: Date[] | undefined

    return (
      width: number,
      xScale: ScaleTime<number, number>,
      focusChanged: FocusChanged
    ) => {
      const brush = brushX()
        .extent([
          [margin.left, 0.5],
          [width - margin.right, height - margin.bottom + 0.5],
        ])
        .on("brush", () => {
          if (event.selection) {
            const selection: number[] = event.selection
            focussedXDomain = selection
              .map(xScale.invert, xScale)
              .map(timeDay.round)
            focusChanged(focussedXDomain)
          }
        })
      const selection = focussedXDomain || [
        timeYear.offset(xScale.domain()[1], -1),
        xScale.domain()[1],
      ]
      g.call(brush).call(brush.move, selection.map(xScale))
    }
  }

  function createFocus(svg: SVGSelection, height: number) {
    svg.attr("aria-hidden", true)

    const xScale = scaleTime().domain(
      extent(data, (d) => d.date) as [Date, Date]
    )
    const yScale = scaleLinear()
      .domain(calcYDomain(xScale.domain()))
      .range([height - margin.bottom, 4])

    const xAxisGroup = svg.append("g")

    const stackedAreaPaths = svg
      .append("g")
      .selectAll("path")
      .data(stackedData)
      .join("path")
      .attr("fill", (_, index) => colors.transparent[index])

    const updateBrush = createBrush(svg.append("g"), height)

    return (width: number, focusChanged: FocusChanged) => {
      svg.attr("viewBox", `0 0 ${width} ${height}`)

      xScale.range([margin.left, width - margin.right])

      xAxisGroup.call(xAxis, xScale, height, width, false)

      stackedAreaPaths.attr(
        "d",
        area<SeriesPoint<DataItem>>()
          .x((d) => xScale(d.data.date) as number)
          .y0((d) => yScale(d[0]) as number)
          .y1((d) => yScale(d[1]) as number)
      )
      updateBrush(width, xScale, focusChanged)
    }
  }

  const createMarker = (div: HTMLDivSelection, color: string, width = 8) =>
    div
      .append("svg")
      .attr("viewBox", "-1 -1 2 2")
      .attr("width", width)
      .append("circle")
      .attr("r", 0.9)
      .attr("fill", color)

  const createDefinition = (div: HTMLDivSelection, text: string) =>
    div.append("span").attr("class", "definition").text(text)

  function createHoverLabel(div: HTMLDivSelection) {
    const value = (div: HTMLDivSelection, type: string) =>
      div.append("strong").attr("class", `value ${type}`)

    div
      .attr("class", "ui left pointing basic black label")
      .style("position", "absolute")
      .style("display", "none")
      .call((div) => div.append("h4"))
      .call(createMarker, colors.base[0])
      .call(createDefinition, `${texts.foundations}:`)
      .call(value, "foundations")
      .call(createDefinition, `${texts.movingAverage}:`)
      .call(value, "avg-foundations")
      .call(createMarker, colors.base[1])
      .call(createDefinition, `${texts.naturalExits}:`)
      .call(value, "natural-exits")
      .call(createDefinition, `${texts.movingAverage}:`)
      .call(value, "avg-natural-exits")
      .call(createMarker, colors.base[2])
      .call(createDefinition, `${texts.insolvencies}:`)
      .call(value, "insolvencies")
      .call(createDefinition, `${texts.movingAverage}:`)
      .call(value, "avg-insolvencies")

    return {
      update: (
        hoverX: number,
        hoverY: number,
        viewportHoverX: number,
        d: DataItem
      ) => {
        div.select("h4").text(widget.i18n().dateFormatter(d.date))
        div.select(".foundations").text(d.foundations)
        div
          .select(".avg-foundations")
          .text(Math.round(d.foundationsMovingAverage))
        div.select(".natural-exits").text(d.naturalExits)
        div
          .select(".avg-natural-exits")
          .text(Math.round(d.naturalExitsMovingAverage))
        div.select(".insolvencies").text(d.insolvencies)
        div
          .select(".avg-insolvencies")
          .text(Math.round(d.insolvenciesMovingAverage))

        const { height: hoverHeight, width: hoverWidth } = div
          .node()!
          .getBoundingClientRect()
        const pointLeft = viewportHoverX + hoverWidth + 40 < window.innerWidth
        div.attr(
          "class",
          `ui ${pointLeft ? "left" : "right"} pointing basic black label`
        )
        const labelX = pointLeft ? hoverX + 16 : hoverX - hoverWidth - 16
        const labelY = hoverY - hoverHeight / 2
        div.style("transform", `translate(${labelX}px,${labelY}px)`)
      },
      setVisible: (isVisible: boolean) => {
        div.style("display", (isVisible ? null : "none") as string)
      },
    }
  }

  function createLegend(container: HTMLElement) {
    select(container)
      .append("div")
      .attr("class", "legend")
      .call(createMarker, colors.base[0], 12)
      .call(createDefinition, texts.foundations)
      .call(createMarker, colors.base[1], 12)
      .call(createDefinition, texts.naturalExits)
      .call(createMarker, colors.base[2], 12)
      .call(createDefinition, texts.insolvencies)
  }

  function drawWidget() {
    const chartContainer = select(widget.container)
      .append("div")
      .style("position", "relative")

    const hoverLabel = createHoverLabel(chartContainer.append("div"))

    const updateChart = createChart(
      chartContainer.append("svg"),
      chartHeight,
      hoverLabel.update,
      hoverLabel.setVisible
    )

    const updateFocus = createFocus(
      select(widget.container).append("svg"),
      focusHeight
    )

    createLegend(widget.container)

    return function redrawWidget() {
      const width = widget.container.getBoundingClientRect().width
      updateFocus(width, (xFocusDomain) => updateChart(width, xFocusDomain))
    }
  }

  return { draw: drawWidget() }
}
