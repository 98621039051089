export type Params<T = any> = { [key: string]: T }

export function clone<T>(object: Params<T>): Params<T> {
  return mixin({}, object)
}

export function concat<T>(object1: Params<T>, object2: Params<T>): Params<T> {
  return mixin(clone(object1), object2)
}

export function mixin<T>(source: Params<T>, target: Params<T>): Params<T> {
  Object["assign"](source, target)
  return source
}
