import { alreadyDrawn, Renderer } from "../../viz/renderer"
import { Widget } from "../../viz/widget"
import { SearchResults } from "../northdata-api"
import { buildStaticMapUrl } from "../static-map"

export const insolvenciesStaticMap: Renderer = function (
  widget: Widget,
  data: SearchResults
) {
  if (!data?.results || data?.results?.length === 0) {
    throw new Error("no data")
  }

  const companies = data
    .results!.filter((result) => result.company)
    .map((result) => result.company!)
    .slice(0, Number(widget.container.dataset.maxItems ?? 3))

  const image = widget.container as HTMLImageElement
  const width = image.getBoundingClientRect().width
  const height = image.getBoundingClientRect().height
  image.src = buildStaticMapUrl(
    companies
      .filter(({ address }) => address.lat && address.lng)
      .map(({ address }) => [address.lat!, address.lng!]),
    Math.floor(Math.max(width, 240)),
    Math.floor(Math.max(height, 240))
  )

  return alreadyDrawn
}
