import { importMap } from "../../dynamic-modules"
import { alreadyDrawn, Renderer } from "../../viz/renderer"
import { Widget } from "../../viz/widget"
import { createContent } from "../map-marker"
import { Icon, Map, MapMarkerOptions } from "../map"
import { teal, tealHover, olive, oliveHover } from "../colors"
import { trilingual } from "../../viz/i18n"

interface Entry {
  city: string
  id: number
  lat?: number
  lng?: number
  name: string
  rank: number
  prev_rank: number
  uri: string
}

interface InnoRanking {
  commercial: Entry[]
  research: Entry[]
}

async function initializeMap(mapElement: HTMLElement) {
  const { drawMap } = await importMap()
  return drawMap(mapElement)
}

function addMarkers(map: Map, mapMarkers: MapMarkerOptions[]) {
  map.addMarkers(mapMarkers)
  map.fitBounds()
}

function createMarkers(
  entries: Entry[],
  category: string,
  icon: Icon,
  color: string,
  colorHover: string
): MapMarkerOptions[] {
  return entries
    .filter((entry) => entry.lat && entry.lng)
    .map((entry) => {
      const deltaRank = entry.prev_rank - entry.rank
      const deltaRankFormatted = `${deltaRank > 0 ? "+" : ""}${deltaRank}`
      const rank = trilingual(
        `Innovation Champion ${category} # ${entry.rank} (${deltaRankFormatted})`,
        `Innovation Champion ${category} Platz ${entry.rank} (${deltaRankFormatted})`,
        `Leader en ‟innovation”`
      )

      return {
        id: entry.id.toString(),
        lat: entry.lat!,
        lng: entry.lng!,
        title: entry.name,
        icon,
        color,
        colorHover,
        content: createContent({
          title: `${entry.name}, ${entry.city}`,
          href: entry.uri,
          extraTexts: [rank],
        }),
      }
    })
}

export const innorankingMap: Renderer = function (
  widget: Widget,
  data: InnoRanking
) {
  async function initialize() {
    const map = await initializeMap(widget.container)

    const commercialMarkers = createMarkers(
      data.commercial,
      trilingual("Commercial", "Wirtschaft", "Economie"),
      "industry",
      olive,
      oliveHover
    )
    const researchMarkers = createMarkers(
      data.research,
      trilingual("Research", "Forschung", "Recherche"),
      "flask",
      teal,
      tealHover
    )

    addMarkers(map, [...commercialMarkers, ...researchMarkers])
  }

  initialize()

  return alreadyDrawn
}
