import { select } from "d3-selection"

export function tooltip() {
  const tooltip = select("body")
    .append("div")
    .attr("class", "tooltip ui basic black label")
    .style("position", "fixed")
    .style("top", 0)
    .style("left", 0)
    .style("pointer-events", "none")
    .style("visibility", "hidden")

  return {
    position(clientX: number, clientY: number) {
      const distance = 16
      const clientWidth = document.documentElement.clientWidth
      const width = tooltip.node()!.offsetWidth
      const height = tooltip.node()!.offsetHeight

      const leftPointing = clientX + width + distance < clientWidth

      const left = leftPointing
        ? clientX + distance
        : clientX - width - distance
      const top = clientY - height / 2

      tooltip
        .style("transform", `translate(${left}px, ${top}px)`)
        .classed("left right pointing", false)
        .classed(leftPointing ? "left" : "right", true)
        .classed("pointing", true)
      return this
    },
    visible(makeVisible: boolean) {
      tooltip
        .interrupt()
        .transition()
        .delay(makeVisible ? 0 : 100)
        .duration(0)
        .style("visibility", () => (makeVisible ? null : "hidden"))
      return this
    },
    content(content: string) {
      tooltip.html(content)
      return this
    },
  }
}
