import { Data } from "../base"
import { alreadyDrawn, Renderer, Rendition } from "../renderer"
import { Widget } from "../widget"
import * as Popup from "../../util/popup"
import * as Ui from "../../util/ui"
import * as Dom from "../../util/dom"

export const pubShop: Renderer = function (
  widget: Widget,
  data: Data
): Rendition {
  const showPopup = widget.handlerValue("popup") || Popup.show

  const onClick = function () {
    Ui.setWaiting(true)
    const request = new XMLHttpRequest()
    request.open("POST", "/_api/shop/v1/products")
    request.setRequestHeader("Content-Type", "application/json")
    function onError(error: any) {
      console.error("cannot list pubs", error)
    }
    request.onerror = function (error) {
      Ui.setWaiting(false)
      onError(error)
    }
    request.onload = function () {
      Ui.setWaiting(false)
      console.info(request.status)
      if (request.status >= 400) {
        onError(request.responseText || request.statusText)
      }
      console.info("ok", request.responseText)
      const data = JSON.parse(request.responseText)
      console.info("data", data)
    }
    request.send(JSON.stringify(widget.options))
    return false
  }

  Dom.onEvent("click", widget.container, "*", onClick)

  return alreadyDrawn
}
