import { getI18n } from "../viz/i18n"

const currencySymbols: { [key: string]: string | undefined } = {
  EUR: "€",
  USD: "$",
  GBP: "£",
}

const i18n = getI18n()
const decimalFormatter = i18n.decimalFormatter
type CurrencyFormatOptions = {
  separator?: string
  prefix?: boolean
  plain?: boolean
}
export function formatCurrency(
  value: number,
  currency: string,
  {
    separator = "\u00A0", // non-breaking space
    prefix = false,
    plain = false,
  }: CurrencyFormatOptions = {}
) {
  if (typeof currency !== "string") {
    currency = ""
  }

  const formatttedValue = decimalFormatter(value)
  const symbol = plain ? currency : currencySymbols[currency] ?? currency
  if (prefix) {
    return symbol + separator + formatttedValue
  } else {
    return formatttedValue + separator + symbol
  }
}
