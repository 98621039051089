import $ from "jquery"
import { Map, MapMarkerOptions } from "../map"
import { importMap } from "../../dynamic-modules"

export interface SearchResultMap {
  addNewMarkers: () => void
}

interface SearchResultMarker {
  id: string
  mapMarker: MapMarkerOptions
  element: HTMLElement
}

function createMapMarkersFromSearchResults(): SearchResultMarker[] {
  const resultElements = document.querySelectorAll<HTMLElement>(
    ".search-results .event"
  )
  const searchResultMarkers: SearchResultMarker[] = []
  resultElements.forEach((resultElement) => {
    const dataset = resultElement.dataset
    if (dataset.id && dataset.lat && dataset.lng) {
      const mapMarker: MapMarkerOptions = {
        content: resultElement.querySelector(".content")!.outerHTML,
        title: resultElement.querySelector(".title")?.textContent ?? "",
        id: dataset.id,
        lat: Number(dataset.lat),
        lng: Number(dataset.lng),
        onActive: () => resultElement.classList.add("active"),
        onInactive: () => resultElement.classList.remove("active"),
      }
      searchResultMarkers.push({
        id: dataset.id,
        mapMarker,
        element: resultElement,
      })
    }
  })
  return searchResultMarkers
}

export async function drawSearchResultMap(
  mapElement: HTMLElement
): Promise<SearchResultMap> {
  let map: Map
  const markerIds: string[] = []

  function addMarkersToMap(searchResultMarkers: SearchResultMarker[]) {
    if (map) {
      const newMarkers = searchResultMarkers.filter(
        ({ id }) => markerIds.indexOf(id) === -1
      )
      map.addMarkers(newMarkers.map((marker) => marker.mapMarker))
      newMarkers.forEach((marker) => {
        markerIds.push(marker.id)
        marker.element.addEventListener("mouseover", () =>
          map.activateMarker(marker.id)
        )
        marker.element.addEventListener("mouseout", () =>
          map.deactivateMarker(marker.id)
        )
      })
    }
  }

  function addSearchAreaOverlay() {
    const dataset = $(mapElement).data()
    if (dataset.area && dataset.area.coord && dataset.area.radiusKm) {
      map.addCircleOverlay(dataset.area.coord, dataset.area.radiusKm)
    }
    if (dataset.area && dataset.area.bbox) {
      const [lng1, lat1, lng2, lat2] = dataset.area.bbox
      map.addRectangleOverlay([
        [lat1, lng1],
        [lat2, lng2],
      ])
    }
  }

  async function initializeMap() {
    const mapMarkers = createMapMarkersFromSearchResults()
    if (mapMarkers.length > 0) {
      $(mapElement).closest(".map.column").show()
      const { drawMap } = await importMap()
      map = drawMap(mapElement)
      addMarkersToMap(mapMarkers)
      addSearchAreaOverlay()
      map.fitBounds()
    }
  }

  await initializeMap()

  return {
    addNewMarkers: () => {
      if (map) {
        addMarkersToMap(createMapMarkersFromSearchResults())
        map.fitBounds()
      }
    },
  }
}
