let buttons: NodeListOf<HTMLButtonElement>
export function setWaiting(value: boolean): void {
  if (value) {
    buttons = document.querySelectorAll<HTMLButtonElement>(
      "button:not([disabled])"
    )
    for (let i = 0; i < buttons.length; ++i) {
      buttons[i]["disabled"] = true
    }
  } else {
    if (buttons) {
      for (let i = 0; i < buttons.length; ++i) {
        buttons[i]["disabled"] = false
      }
    }
  }
  document.body.classList.toggle("waiting", value)
}
