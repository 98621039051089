import { bisector } from "d3-array"

export function getDataItemByDate<T>(
  data: T[],
  accessor: (dataItem: T) => Date,
  date: Date
): T {
  const bisectDate = bisector<T, Date>(accessor).right
  const index = bisectDate(data, date)
  return data[index === 0 ? index : index - 1]
}
