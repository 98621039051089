import { create } from "d3-selection"
import { isoDateParser } from "../util/formatter"
import { trilingual, getI18n } from "../viz/i18n"
import { Company } from "./northdata-api"

const i18n = getI18n()

export interface Options {
  title: string
  href?: string
  extraTexts?: string[]
}

function insertIf(condition: boolean, value: () => string) {
  return condition ? [value()] : []
}

export function createContent(options: Options) {
  return create("div")
    .attr("class", "content")
    .call((content) =>
      content
        .append("div")
        .attr("class", "summary")
        .append(options.href ? "a" : "span")
        .attr("class", "title")
        .attr("href", () => options.href ?? null)
        .text(options.title)
    )
    .call(
      (content) =>
        options.extraTexts &&
        options.extraTexts.forEach((text) =>
          content.append("div").attr("class", "extra text").text(text)
        )
    )
    .node()!
}

export function createContentFromCompany(company: Company) {
  const insolvencyDate = isoDateParser(
    company.events?.items.find((event) => event.type === "Insolvency")?.date ??
      ""
  )
  const financialsYear = isoDateParser(
    company.financials?.date ?? ""
  )?.getFullYear()
  const balanceTotal = company.financials?.items.find(
    (financials) => financials.id === "BalanceTotal"
  )?.formattedValue
  const employees = company.financials?.items.find(
    (financials) => financials.id === "Employees"
  )?.formattedValue

  return createContent({
    title: `${company.name.name}, ${company.address.city}`,
    href: company.northDataUrl,
    extraTexts: [
      ...insertIf(
        !!insolvencyDate,
        () =>
          `${trilingual(
            "Insolvency filing",
            "Eröffnung Insolvenzverfahren",
            "Ouverture de procédure d'insolvabilité"
          )} ${insolvencyDate && i18n.dateFormatter(insolvencyDate)}`
      ),
      ...insertIf(
        !!financialsYear && !!balanceTotal,
        () =>
          `${trilingual(
            "Total assets",
            "Bilanzsumme",
            "Bilan total"
          )} ${financialsYear}: ${balanceTotal}`
      ),
      ...insertIf(
        !!financialsYear && !!employees,
        () =>
          `${trilingual(
            "Employees",
            "Mitarbeiter",
            "Employés"
          )} ${financialsYear}: ${employees}`
      ),
    ],
  })
}
