import {
  timeFormatLocale,
  TimeLocaleDefinition,
  TimeLocaleObject,
} from "d3-time-format"
import {
  timeSecond,
  timeMinute,
  timeHour,
  timeDay,
  timeWeek,
  timeMonth,
  timeYear,
} from "d3-time"
import { formatLocale, FormatLocaleDefinition } from "d3-format"

import deDeTimeFormat from "d3-time-format/locale/de-DE.json"
import enUsTimeFormat from "d3-time-format/locale/en-US.json"
import frFrTimeFormat from "d3-time-format/locale/fr-FR.json"

import deDeFormat from "d3-format/locale/de-DE.json"
import enUsFormat from "d3-format/locale/en-US.json"
import frFrFormat from "d3-format/locale/fr-FR.json"

import { getLanguage } from "../util/language"

type NumberFormatter = (n: number) => string
type DateFormatter = (date: Date) => string
type DateStringFormatter = (date: string) => string
export interface NumberFormat {
  accuracy: number
  prefix: string
  postfix: string
}

//
export function trilingual(
  english: string,
  german: string,
  french: string = ""
): string {
  return getLanguage() === "de"
    ? german
    : getLanguage() === "fr"
    ? french
    : english
}

export function getI18n(): I18n {
  return getLanguage() === "de"
    ? German
    : getLanguage() === "fr"
    ? French
    : English
}

export interface I18n {
  timeFormatLocaleDefinition: TimeLocaleDefinition
  numberFormatLocaleDefinition: FormatLocaleDefinition
  createNumberFormatter(
    numberFormat: NumberFormat,
    d3FormatSpecifier: string
  ): NumberFormatter
  decimalFormatter: NumberFormatter
  numberFormatter: NumberFormatter
  dateFormatter: DateFormatter & DateStringFormatter
  timeScaleFormatter: DateFormatter
}

/**
 * Creates a conditional time formatter for time scales.
 * https://github.com/d3/d3-time-format#d3-time-format
 */
function createTimeScaleFormatter(timeLocale: TimeLocaleObject): DateFormatter {
  const formatMillisecond = timeLocale.format(".%L")
  const formatSecond = timeLocale.format(":%S")
  const formatMinute = timeLocale.format("%I:%M")
  const formatHour = timeLocale.format("%I %p")
  const formatDay = timeLocale.format("%a %d")
  const formatWeek = timeLocale.format("%b %d")
  const formatMonth = timeLocale.format("%B")
  const formatYear = timeLocale.format("%Y")

  return (date) =>
    (timeSecond(date) < date
      ? formatMillisecond
      : timeMinute(date) < date
      ? formatSecond
      : timeHour(date) < date
      ? formatMinute
      : timeDay(date) < date
      ? formatHour
      : timeMonth(date) < date
      ? timeWeek(date) < date
        ? formatDay
        : formatWeek
      : timeYear(date) < date
      ? formatMonth
      : formatYear)(date)
}

function createI18n(
  timeFormatLocaleDefinition: TimeLocaleDefinition,
  numberFormatLocaleDefinition: FormatLocaleDefinition
): I18n {
  const timeLocale = timeFormatLocale(timeFormatLocaleDefinition)
  const numberLocale = formatLocale(numberFormatLocaleDefinition)

  const round2 = (x: number) => Math.round(x * 100) / 100
  const _decimalFormatter = numberLocale.format(",.2f")
  const _numberFormatter = numberLocale.format(",.0f")
  const _dateFormatter = timeLocale.format(timeFormatLocaleDefinition.date)

  return {
    timeFormatLocaleDefinition: timeFormatLocaleDefinition,
    numberFormatLocaleDefinition: numberFormatLocaleDefinition,
    createNumberFormatter: function (
      numberFormat: NumberFormat,
      d3FormatSpecifier: string
    ): NumberFormatter {
      const accuracy = numberFormat.accuracy
      const d3formatter = numberLocale.format(d3FormatSpecifier)
      return (value) =>
        numberFormat.prefix +
        d3formatter(value / accuracy) +
        numberFormat.postfix
    },
    decimalFormatter: (x) => _decimalFormatter(round2(x)),
    numberFormatter: (x) => _numberFormatter(Math.round(x)),
    dateFormatter: (date: Date | string) => {
      if (!date) {
        return ""
      }
      if (typeof date === "string") {
        date = new Date(date)
      }
      return _dateFormatter(date)
    },
    timeScaleFormatter: createTimeScaleFormatter(timeLocale),
  }
}

export const German = createI18n(
  deDeTimeFormat as TimeLocaleDefinition,
  { ...deDeFormat, currency: ["€", ""] } as FormatLocaleDefinition
)

export const English = createI18n(
  {
    ...enUsTimeFormat,
    // use international style instead:
    date: "%Y-%m-%d",
  } as TimeLocaleDefinition,
  {
    ...enUsFormat,
    currency: ["€", ""],
  } as FormatLocaleDefinition
)

export const French = createI18n(
  frFrTimeFormat as TimeLocaleDefinition,
  { ...frFrFormat, currency: ["€", ""] } as FormatLocaleDefinition
)
