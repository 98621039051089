import { sanitizeUrl } from "./url"

function manage(form: HTMLFormElement) {
  function showMessage(clazz: string, message?: string) {
    if (message) {
      form.classList.add(clazz)
      const messageField = form.querySelector("." + clazz + ".message p")
      if (messageField) {
        messageField.textContent = message
      }
    } else {
      form.classList.remove(clazz)
    }
  }
  function error(message?: string) {
    showMessage("error", message)
  }
  function success(message?: string) {
    showMessage("success", message)
  }
  const submitButton = form.querySelector("[type='submit']")
  if (!submitButton) {
    throw new Error("submit button missing")
  }
  form.onsubmit = function () {
    error()
    success()
    const formData = new FormData(form)
    const xhr = new XMLHttpRequest()
    xhr.open(form.method, form.action, true)
    xhr.onload = function (e) {
      const responseContentType = xhr
        .getResponseHeader("Content-Type")
        ?.split(";")[0]
      let message = xhr.response
      switch (responseContentType) {
        case "application/json":
          message = JSON.parse(message)["message"]
          break
      }
      if (xhr.status >= 400) {
        submitButton.classList.remove("loading")
        error(message)
      } else {
        if (responseContentType == "application/json" && message) {
          success(message)
        } else {
          const targetUrl = sanitizeUrl(form.getAttribute("target-url"))
          document.location.assign(targetUrl)
        }
      }
      submitButton.classList.remove("loading")
    }
    xhr.send(formData)
    submitButton.classList.add("loading")
    return false
  }
}

export function manageAll() {
  const forms = document.querySelectorAll("form.managed")
  for (let i = 0; i < forms.length; ++i) {
    const form = forms[i] as HTMLFormElement
    manage(form)
  }
}
