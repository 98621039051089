module Cookie {
  export function set(name: string, value: string, days = 365): void {
    value = value || ""
    let expires = ""
    if (days) {
      let date = new Date()
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
      expires = "; expires=" + date.toUTCString()
    }
    document.cookie = name + "=" + value + expires + "; path=/"
  }

  export function get(name: string): string | undefined {
    let nameEQ = name + "="
    let ca = document.cookie.split(";")
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) == " ") c = c.substring(1, c.length)
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length)
    }
  }
}

export module State {
  export function get(): Record<string, any> {
    let stateCookie = Cookie.get("state") || "{}"
    stateCookie = decodeURIComponent(stateCookie)
    // work-around for additional quotes in GAE production SDK 1.3.1
    if (stateCookie.charAt(0) == '"') {
      stateCookie = stateCookie.substring(1)
      let length = stateCookie.length
      if (stateCookie.charAt(length - 1) == '"') {
        stateCookie = stateCookie.substring(0, length - 1)
      }
    }
    try {
      return $.parseJSON(stateCookie) || {}
    } catch (error) {
      if (window["console"]) {
        console.error("cannot parse state cookie", error)
      }
      return {}
    }
  }

  export function set(object: Object): void {
    Cookie.set("state", '"' + encodeURIComponent(JSON.stringify(object)) + '"')
  }

  export function getValue(key: string): any {
    return get()[key]
  }

  export function setValue(key: string, value: any): void {
    let object = get()
    object[key] = value
    set(object)
  }

  export function deleteValue(key: string): void {
    let object = get()
    delete object[key]
    set(object)
  }
}
