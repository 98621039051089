import { LatLngTuple } from "leaflet"
import { geoapifyApiKey } from "./api"
import { toQueryString } from "../util/string"
import { grey } from "./colors"

const lonlat = ([lat, lng]: LatLngTuple) => `lonlat:${lng},${lat}`
const rect = ([latA, lngA]: LatLngTuple, [latB, lngB]: LatLngTuple) =>
  `rect:${lngA},${latA},${lngB},${latB}`

function marker(position: LatLngTuple) {
  const icon = "building"
  return `${lonlat(
    position
  )};type:awesome;color:${grey};size:medium;icon:${icon};shadow:no`
}

function calculateArea(markers: LatLngTuple[]) {
  if (markers.length === 0) {
    return {
      // bounding box of Germany
      area: rect([47.2701114, 5.8663153], [55.099161, 15.0418087]),
    }
  } else if (markers.length === 1) {
    const [lat, lng] = markers[0]
    return {
      center: lonlat([lat, lng]),
      zoom: 14,
    }
  }

  // Based on https://github.com/Leaflet/Leaflet/blob/master/src/geo/LatLngBounds.js
  const north = Math.max(...markers.map(([lat]) => lat))
  const south = Math.min(...markers.map(([lat]) => lat))
  const east = Math.max(...markers.map(([_, lng]) => lng))
  const west = Math.min(...markers.map(([_, lng]) => lng))
  const bufferRatio = 0.2
  const heightBuffer = Math.abs(north - south) * bufferRatio
  const widthBuffer = Math.abs(east - west) * bufferRatio
  return {
    area: rect(
      [north + heightBuffer, east + widthBuffer],
      [south - heightBuffer, west - widthBuffer]
    ),
  }
}

export function buildStaticMapUrl(
  markers: LatLngTuple[],
  width: number,
  height: number
) {
  const query = {
    ...calculateArea(markers),
    style: "osm-bright-smooth",
    width: width,
    height: height,
    marker: markers.map((position) => marker(position)).join("|"),
    format: "jpeg",
    scaleFactor: 1,
    apiKey: geoapifyApiKey,
  }
  return `https://maps.geoapify.com/v1/staticmap?${toQueryString(query)}`
}
