/** object assign polyfill **/

if (typeof Object["assign"] != "function") {
  ;(function () {
    Object["assign"] = function (target: object) {
      console.info("installing Object.assign polyfill")
      ;("use strict")
      if (target === undefined || target === null) {
        throw new TypeError("Cannot convert undefined or null to object")
      }
      let output = Object(target)
      for (let index = 1; index < arguments.length; index++) {
        let source = arguments[index]
        if (source !== undefined && source !== null) {
          for (let nextKey in source) {
            if (Object.prototype.hasOwnProperty.call(source, nextKey)) {
              output[nextKey] = source[nextKey]
            }
          }
        }
      }
      return output
    }
  })()
}

/** strings polyfill */
if (!String.prototype["includes"]) {
  console.info("installing String.includes polyfill")
  String.prototype["includes"] = function (search, start) {
    "use strict"
    if (typeof start !== "number") {
      start = 0
    }

    if (start + search.length > this.length) {
      return false
    } else {
      return this.indexOf(search, start) !== -1
    }
  }
}
if (!String.prototype["startsWith"]) {
  String.prototype["startsWith"] = function (search, pos) {
    return this.substr(!pos || pos < 0 ? 0 : +pos, search.length) === search
  }
}
