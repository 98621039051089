import $ from "jquery"
import "datatables.net-se"
import "datatables.net-se/css/dataTables.semanticui.css"
import { trilingual } from "../viz/i18n"
import { State } from "../util/state"

const texts = {
  info: trilingual(
    "Showing _START_ to _END_ of _TOTAL_ entries",
    "Einträge _START_ bis _END_ von _TOTAL_",
    "Affichage du _START_ à la _END_ du _TOTAL_ des entrées"
  ),
  infoEmpty: trilingual(
    "Showing 0 to 0 of 0 entries",
    "Einträge 0 bis 0 von 0",
    "Affichage de 0 à 0 sur 0 entrées"
  ),
  infoFiltered: trilingual(
    "(filtered from _MAX_ total entries)",
    "(aus insgesamt _MAX_ Einträgen)",
    "(filtré à partir de _MAX_ entrées totales)"
  ),
  lengthMenu: trilingual(
    "Show _MENU_ entries",
    "Zeige _MENU_ Einträge",
    "Afficher les entrées du _MENU_"
  ),
  search: "", // remove search label
  searchPlaceholder: trilingual("Search", "Suche", "Recherche"),
  zeroRecords: trilingual(
    "No matching records found",
    "Keine passenden Einträge gefunden",
    "Aucune entrée correspondante trouvée"
  ),
  paginate: {
    first: "", // not used
    last: "", // not used
    next: "<i class='chevron right icon'></i>",
    previous: "<i class='chevron left icon'></i>",
  },
}

function initializeDateOrdering() {
  const jQueryFn = jQuery.fn as any
  if (
    !jQueryFn?.dataTableExt ||
    jQueryFn.dataTableExt.oSort["simple-date-pre"]
  ) {
    return
  }

  jQuery.extend(jQueryFn.dataTableExt.oSort, {
    "simple-date-pre": function (date: string) {
      if (typeof date !== "string") {
        return 0
      }

      date = date.replace(" ", "")

      let day, month, year
      let dateParts = date.split(/[\.\/]/)
      if (dateParts.length === 1 && date.indexOf("-") !== -1) {
        dateParts = date.split("-")
        ;[year, month, day] = dateParts
      } else {
        ;[day, month, year] = dateParts
      }

      const val = parseInt(year + month + day, 10)
      return isNaN(val) ? 0 : val
    },
    "simple-date-asc": function (a: number, b: number) {
      return a < b ? -1 : a > b ? 1 : 0
    },
    "simple-date-desc": function (a: number, b: number) {
      return a < b ? 1 : a > b ? -1 : 0
    },
  })
}

function createPersistOrderCallback(key: string) {
  return function (this: DataTables.Settings) {
    const table = $(this).DataTable()
    const newOrder = table.order()
    if (newOrder) {
      State.setValue(key, newOrder)
    }
  }
}

function initializeInnovationRanking() {
  $("table.ranking.dataTable").each((_, element) => {
    const table = $(element)
    const rowCount = table.find("> tbody > tr").length
    table.DataTable({
      paging: rowCount > 25,
      pageLength: 50,
      ordering: true,
      info: true,
      searching: true,
      scrollX: true,
      columnDefs: [
        // Order ranking values descending first
        {
          targets: [3, 4, 5, 6, 7, 8],
          orderSequence: ["desc", "asc"],
        },
      ],
      language: texts,
    })
  })

  $("#innovation-ranking-menu .item").tab({
    onVisible: function () {
      // Adjust column sizes of hidden table that now becomes visible
      $(this).find("table.dataTable").DataTable().columns.adjust().draw()
    },
  })
}

function initializeCoverageTables() {
  const reinitializeTooltipsCallback = function (
    this: DataTables.SettingsLegacy
  ) {
    $(this)
      .find(".tooltip-host")
      .popup({ position: "top center", hoverable: true })
  }

  $("table.countries.dataTable").DataTable({
    paging: false,
    ordering: true,
    order: [[0, "asc"]],
    searching: true,
    info: false,
    language: texts,
    drawCallback: reinitializeTooltipsCallback,
  })

  $("table.sources.dataTable").DataTable({
    paging: false,
    ordering: true,
    order: [],
    searching: true,
    language: texts,
    drawCallback: reinitializeTooltipsCallback,
  })

  $("table.indicators.dataTable").DataTable({
    paging: false,
    ordering: true,
    order: [],
    searching: true,
    info: false,
    language: texts,
    drawCallback: reinitializeTooltipsCallback,
  })
}

function initializeWatchListTable() {
  const table = $("table.watches.dataTable")
  if (!table.length) {
    return
  }

  initializeDateOrdering()

  const order = State.getValue("watchesOrder")
  table.DataTable({
    paging: true,
    pageLength: 50,
    lengthChange: false,
    ordering: true,
    order: order ?? [[1, "asc"]],
    columnDefs: [
      { type: "string", targets: 0 },
      { type: "simple-date", targets: 4 },
      { type: "simple-date", targets: 5 },
      { orderable: false, targets: 6 },
    ],
    searching: true,
    language: texts,
    drawCallback: createPersistOrderCallback("watchesOrder"),
  })
}

function initializeDocumentsTable() {
  const table = $("table.documents.dataTable")
  if (!table.length) {
    return
  }

  initializeDateOrdering()

  const order = State.getValue("documentsOrder")
  const orderCallback = createPersistOrderCallback("documentsOrder")
  const pageLength = 25
  const rowCount = table.find("> tbody > tr").length
  const enablePagination = rowCount > pageLength
  table.DataTable({
    paging: enablePagination,
    pageLength: pageLength,
    lengthChange: false,
    ordering: true,
    order: order ?? [[0, "desc"]],
    columnDefs: [
      { type: "simple-date", targets: 0 },
      { type: "simple-date", targets: 3 },
      { orderable: false, targets: 4 },
    ],
    searching: true,
    language: texts,
    drawCallback: function () {
      orderCallback.apply(this)
      $(this).find(".tooltip-icon").popup({ position: "top center" })
    },
  })
}

export function initializeDocumentShopTable(element: JQuery) {
  initializeDateOrdering()

  const pageLength = 15
  const rowCount = element.find("> tbody > tr").length
  const enablePagination = rowCount > pageLength
  element.DataTable({
    paging: enablePagination,
    pageLength: pageLength,
    lengthChange: false,
    searching: false,
    ordering: true,
    info: false,
    autoWidth: false,
    order: [[2, "desc"]],
    columnDefs: [
      { orderable: false, targets: 0 },
      { type: "simple-date", targets: 2 },
    ],
    language: {
      ...texts,
      zeroRecords: trilingual(
        "No original documents are available for this company.",
        "Für diese Firma sind keine Originaldokumente verfügbar.",
        "Aucun document original n’est disponible pour cette entreprise."
      ),
    },
  })
}

export function initializeInvoicesTable(element: JQuery) {
  initializeDateOrdering()

  const pageLength = 8
  const rowCount = element.find("> tbody > tr").length
  const enablePagination = rowCount > pageLength
  element.DataTable({
    destroy: true,
    paging: enablePagination,
    pageLength: pageLength,
    lengthChange: false,
    searching: false,
    info: false,
    ordering: true,
    order: [[1, "desc"]],
    columnDefs: [
      { orderable: false, targets: 0 },
      { type: "simple-date", targets: 1 },
      { orderable: false, targets: 3 },
    ],
    language: texts,
    dom: "lfrtp",
  })
}

export function initializeDataTables() {
  // Remove spacing once the datatable has rendered
  $.extend(true, $.fn.dataTable.defaults, {
    initComplete: function () {
      $(this).removeClass("dt-spacing")
    },
  })

  $(document).on("init.dt", function (e, ctx: DataTables.SettingsLegacy) {
    if (e.namespace !== "dt") {
      return
    }

    const api = new $.fn.dataTable.Api(ctx)
    const inputs = $(
      "div.dataTables_filter .ui.input",
      api.tables().containers()
    )

    inputs
      .not(".labeled")
      .addClass("left icon labeled")
      .prepend("<i class='icon search'></i>")
  })

  initializeInnovationRanking()
  initializeCoverageTables()
  initializeWatchListTable()
  initializeDocumentsTable()
}
