import "./polyfill"

// Polyfills for enabling abortable fetch
// fetch uses Promises, so include a polyfill for that, too
// https://caniuse.com/promises
// https://caniuse.com/fetch
// https://caniuse.com/abortcontroller
import "promise-polyfill/src/polyfill"
import "unfetch/polyfill"
import "abortcontroller-polyfill"

import { Bizq } from "./viz-base"
;(window as any).Bizq = Bizq
;(window as any).NorthData = Bizq
