import $ from "jquery"
import { importMap } from "../../dynamic-modules"
import { alreadyDrawn, Renderer } from "../../viz/renderer"
import { Widget } from "../../viz/widget"
import { MapMarkerOptions, Map } from "../map"
import { calendarSettings } from "../calendar"
import { isoDateFormatter } from "../../util/formatter"
import { toQueryString } from "../../util/string"
import { json } from "d3-fetch"
import { create } from "d3-selection"
import { timeDay } from "d3-time"
import { Company, SearchResults } from "../northdata-api"
import { createContentFromCompany } from "../map-marker"
import { debounce } from "../../util/dom"

interface FormData {
  country: string
  minDate: Date
  maxDate: Date
}

function createFeedEvent(company: Company) {
  return create("div")
    .attr("class", "event")
    .attr("data-id", company.id)
    .call((event) =>
      event
        .append("div")
        .attr("class", "label")
        .append("i")
        .attr("class", "circular building icon")
    )
    .call((event) => event.append(() => createContentFromCompany(company)))
    .node()!
}

function highlightFeedEvent(companyId: string, highlight: boolean) {
  const event = $(`.ui.insolvencies.feed > .event[data-id="${companyId}"]`)
  highlight ? event.addClass("active") : event.removeClass("active")
}

function updateList(map: Map, companyResult: SearchResults | undefined) {
  const list = $(".ui.insolvencies.feed")
  list.empty()
  if (companyResult?.results) {
    companyResult.results
      .filter((result) => result.company)
      .map(({ company }) => {
        const eventElement = createFeedEvent(company!)
        eventElement.addEventListener("mouseover", () =>
          map.activateMarker(company!.id)
        )
        eventElement.addEventListener("mouseout", () => {
          map.deactivateMarker(company!.id)
          eventElement.classList.remove("active")
        })
        return eventElement
      })
      .forEach((element) => list.append(element))
  }
}

function updateMap(map: Map, companyResult: SearchResults | undefined) {
  map.clearMarkers()
  if (companyResult?.results) {
    const mapMarkers: MapMarkerOptions[] = companyResult.results
      .filter(({ company }) => company?.address.lat && company?.address.lng)
      .map(({ company }) => ({
        id: company!.id,
        lat: company!.address.lat!,
        lng: company!.address.lng!,
        title: company!.name.name,
        content: createContentFromCompany(company!),
        onActive: () => highlightFeedEvent(company!.id, true),
        onInactive: () => highlightFeedEvent(company!.id, false),
      }))
    map.addMarkers(mapMarkers)
    map.fitBounds()
  }
}

async function initializeMap(mapElement: HTMLElement) {
  const { drawMap } = await importMap()
  return drawMap(mapElement)
}

async function fetchData(formData: FormData) {
  const queryParameters = {
    ...formData,
    minDate: isoDateFormatter(formData.minDate),
    maxDate: isoDateFormatter(formData.maxDate),
  }
  return await json<SearchResults>(
    `/bigins.json?${toQueryString(queryParameters)}`
  )
}

function initializeForm(onChange: (formData: FormData) => void) {
  // initial default values
  const defaultCountry = "DE"
  const rangeEnd = new Date()
  const rangeStart = timeDay.offset(rangeEnd, -21)

  const form = $(".ui.insolvencies.form")
  const countrySelection = form.find(".ui.country.dropdown")
  const rangeStartCalendar = form.find(".ui.calendar.range-start")
  const rangeEndCalendar = form.find(".ui.calendar.range-end")

  const getValues = () => ({
    country: countrySelection.dropdown("get value"),
    minDate: rangeStartCalendar.calendar("get date"),
    maxDate: rangeEndCalendar.calendar("get date"),
  })
  const triggerChange = () => onChange(getValues())

  countrySelection.find("input").val(defaultCountry)
  countrySelection.dropdown({
    onChange: triggerChange,
  })
  rangeStartCalendar.calendar({
    ...calendarSettings,
    endCalendar: rangeEndCalendar,
    onChange: triggerChange,
  })
  rangeEndCalendar.calendar({
    ...calendarSettings,
    today: true,
    startCalendar: rangeStartCalendar,
    onChange: triggerChange,
  })
  rangeStartCalendar.calendar("set date", rangeStart, true, false)
  rangeEndCalendar.calendar("set date", rangeEnd, true, false)

  triggerChange()
}

export const insolvenciesMap: Renderer = function (widget: Widget, _data: any) {
  const setLoading = debounce((isLoading: boolean) => {
    const dimmer = $(widget.container).find(".ui.dimmer")
    isLoading ? dimmer.dimmer("show") : dimmer.dimmer("hide")
  }, 100)
  const showEmptyMessage = (show: boolean) => {
    const message = $(".ui.info.message")
    show ? message.show() : message.hide()
  }

  async function initialize() {
    const mapPromise = initializeMap(widget.container)
    initializeForm(
      debounce(async function onChange(values: FormData) {
        try {
          setLoading(true)
          const dataPromise = fetchData(values)
          const [map, companyResult] = await Promise.all([
            mapPromise,
            dataPromise,
          ])

          const hasResults = (companyResult?.results?.length ?? 0) > 0
          showEmptyMessage(!hasResults)
          updateMap(map, companyResult)
          updateList(map, companyResult)
        } finally {
          setLoading(false)
        }
      }, 100)
    )
  }

  initialize()

  return alreadyDrawn
}
