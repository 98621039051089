import { mixin } from "../util/params"
import { Data } from "./base"
import { Widget } from "./widget"

export interface Rendition {
  draw(): void
}
export type Renderer = (widget: Widget, data: Data) => Rendition
export type Renderers = { [key: string]: Renderer }

export const alreadyDrawn: Rendition = {
  draw() {},
}

const registeredRenderers: Renderers = {}

export function registerRenderers(renderers: Renderers) {
  mixin(registeredRenderers, renderers)
}

export function getRegisteredRenderer(key: string) {
  return registeredRenderers[key]
}
