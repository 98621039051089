export function sanitizeUrl(url: string | null | undefined) {
  const defaultUrl = "/"
  const sanitizedUrl = url?.toLowerCase() || defaultUrl
  const urlMatch = /^(https?:\/\/(.+)|(\/.+))$/.exec(sanitizedUrl)

  if (urlMatch) {
    var absolutePath = urlMatch[2]
    var relativePath = urlMatch[3]

    if (absolutePath) {
      if (absolutePath.startsWith(document.location.host)) {
        console.log("url is valid: ", sanitizedUrl)
        return sanitizedUrl
      }
    } else if (relativePath) {
      console.log("url is valid: ", sanitizedUrl)
      return sanitizedUrl
    }
  }

  console.error("url is invalid: ", sanitizedUrl)
  return defaultUrl
}
