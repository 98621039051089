import { hsl, HSLColor } from "d3-color"

export type Coloring = { standard: string; light: string; bright: string }

export function createColoring(color: HSLColor): Coloring {
  return {
    standard: color.toString(),
    light: color.brighter(1.15).toString(),
    bright: hsl(color.h, color.s * 1.3, color.l * 0.9).toString(),
  }
}

export function createPalette(color: HSLColor, size: number): Coloring[] {
  const result: Coloring[] = []
  const dHue = 360 / size + 1
  let hue = color.h
  for (let i = 0; i < size; ++i) {
    color.h = (hue + i * dHue) % 360
    result.push(createColoring(color))
  }
  return result
}
