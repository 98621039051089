import $ from "jquery"

export function initializeTooltips() {
  initializeTooltip(
    $(".tooltip-host").filter((_index, element) => {
      return (
        $(element).closest(
          "#financial-filter-prototype, #event-filter-prototype"
        ).length === 0
      )
    })
  )
}

export function initializeTooltip(elements: JQuery<HTMLElement>) {
  elements.each((_index, element) => {
    const $element = $(element)

    // If the next sibling element is an inline popup move that popup to body (where all popups are located by default).
    // This will prevent undesired styles to apply to the popup such as in menu dropdowns.
    const $popup = $element
      .next($.fn.popup.settings.selector?.popup)
      .detach()
      .appendTo($.fn.popup.settings.context ?? "body")

    $(element).popup({
      popup: $popup.length === 1 ? $popup : undefined,
      movePopup: false,
      hoverable: true,
      addTouchEvents: $element.data("touch") ?? true,
      duration: 100,
      delay: {
        show: $element.data("immediate") ? 50 : 500,
        hide: 70,
      },
    })
  })
}
