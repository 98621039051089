import { VERSION } from "./env"
import { registerRenderers } from "./viz/renderer"
import { Widget as WidgetClass } from "./viz/widget"
import { barChart } from "./viz/widgets/barchart"
import { contact } from "./viz/widgets/contacts"
import { dendrogram } from "./viz/widgets/dendrogram"
import { drillDown } from "./viz/widgets/drilldown"
import { financials } from "./viz/widgets/financials"
import { graph } from "./viz/widgets/graph"
import { history } from "./viz/widgets/history"
import { overview } from "./viz/widgets/overview"
import { personTable } from "./viz/widgets/persontable"
import { pubShop } from "./viz/widgets/pubshop"
import { pubTable } from "./viz/widgets/pubtable"
import { searchResults } from "./viz/widgets/searchresults"
import { sheet } from "./viz/widgets/sheet"
import { vita } from "./viz/widgets/vita"

export namespace Bizq {
  export const Widget = WidgetClass

  registerRenderers({
    barChart,
    drillDown,
    graph,
    dendrogram,
    history,
    vita,
    pubTable,
    pubShop,
    personTable,
    sheet,
    financials,
    contact,
    overview,
    searchResults,
  })

  console.info(
    "started North Data Widget API " +
      (typeof VERSION === "undefined" ? "in debug mode" : VERSION)
  )
}

export const NorthData = Bizq
