export type SupportedLanguage = "de" | "en" | "fr"

const language: SupportedLanguage =
  document.documentElement.lang === "en"
    ? "en"
    : document.documentElement.lang === "fr"
    ? "fr"
    : "de"

export function getLanguage(): SupportedLanguage {
  return language
}
